@import url(https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&display=swap);
body {
  margin: 0;
  font-family: 'Gloria Hallelujah', cursive;
  font-size: 16px;
  line-height: 1.8;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --wired-toggle-on-color: darkorange;
}

main {
  padding: 16px;
  text-align: center;
}

wired-card {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  display: block;
}

section {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px 0;
}

wired-button {
  background: lightblue;
  color: darkcyan;
  margin: 8px;
}

wired-button.btn-copy {
  background: lightgreen;
  color: darkolivegreen;
  margin: 8px;
}

wired-input {
  width: 200px;
  /* font-family: inherit; */
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  letter-spacing: 0.5em;
}

.copied {
  color: darkolivegreen;
}

.toggle-kata {
  font-size: 12px;
  margin: 0 10px;
}

.toggle-notes {
  font-size: 10px;
  color: darkorange;
}

.pronunciation {
  display: flex;
  justify-content: center;
}

.pronunciation-label {
  font-weight: 700;
  margin-right: 8px;
}

/* Add left margin for the words, if more than 1. */
.pronunciation-values > *:not(:first-child) {
  margin-left: 8px;
}

.foot-notes {
  font-size: 0.65em;
  color: grey;
  line-height: 140%;
}

